import React, { ReactElement } from 'react'
import { lighten, makeStyles, createStyles, withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Box } from '@material-ui/core'

const BorderLinearProgress = withStyles({
    root: {
        height: 10,
        backgroundColor: lighten('#ff6c5c', 0.5),
        color: 'blue'
    },
    bar: {
        borderRadius: 30,
        backgroundColor: 'green'
    }
})(LinearProgress)

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1
        },
        margin: {
            margin: 10
        }
    })
)

function BarComponent({ progress } : {progress: number}) : ReactElement {
    const classes = useStyles()

    return(
        <Box
            className={classes.root}
            color="black"
            border={1}
            bgcolor="background.paper"
            width="80%"
            textAlign="center"
            alignItems="center"
            marginLeft="auto"
            marginRight="auto"
        >
            <div>
                <BorderLinearProgress
                    className={classes.margin}
                    variant="determinate"
                    value={progress}
                />
            </div>
        </Box>
    )
}

export default BarComponent