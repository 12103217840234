import React, { useState } from 'react'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import BarComponent from './BarComponent'
import HumidityGraphComponent from './HumidityGraphCompnent'
import { CSVHumidityDataPoint } from '../Types/CSVHumidityDataPoint'
import { CSVTemperatureDataPoint } from '../Types/CSVTemperatureDataPoint'
import GraphComponent from './GraphCompnent'

export default function ContentEx({ fetchedHumidityCSVData, fetchedTemperatureCSVData }
    : { fetchedHumidityCSVData: CSVHumidityDataPoint[], fetchedTemperatureCSVData: CSVTemperatureDataPoint[] }): JSX.Element {
    const [dateButton, setDateButton] = useState<string>('12H')
    const { value0, value1, value2, value3 } = fetchedHumidityCSVData[fetchedHumidityCSVData.length - 1]
    const progress0: number = ((30100 - value0) / (30100 - 20000) * 100)
    const progress1: number = ((30100 - value1) / (30100 - 20000) * 100)
    const progress2: number = ((30100 - value2) / (30100 - 20000) * 100)
    const progress3: number = ((30100 - value3) / (30100 - 20000) * 100)

    function getMinDate() {
        const maxDate = fetchedHumidityCSVData[fetchedHumidityCSVData.length - 1].time
        let d = new Date(maxDate)

        switch(dateButton) {
            case '1H':
                d.setHours(d.getHours() - 1)
                break
            case '3H':
                d.setHours(d.getHours() - 2)
                break
            case '6H':
                d.setHours(d.getHours() - 6)
                break
            case '12H':
                d.setHours(d.getHours() - 12)
                break
            case '24H':
                d.setHours(d.getHours() - 24)
                break
            case '3D':
                d.setHours(d.getHours() - 72)
                break
            case '7D':
                d.setHours(d.getHours() - 168)
                break
            case '2W':
                d.setHours(d.getHours() - 336)
                break
            case 'MAX':
                d = new Date(fetchedHumidityCSVData[0].time)
                break
            default:
                d.setHours(d.getHours() - 1)
        }
        return d
    }

    const handleAlignment = (event: React.MouseEvent<HTMLElement>, dateButtonSelected: string) => {
        setDateButton(dateButtonSelected)
    }
    const minDate = getMinDate()
    const cutData = fetchedHumidityCSVData.filter(x => new Date(x.time) >= minDate)
    const cutData2 = fetchedTemperatureCSVData.filter(x => new Date(x.time) >= minDate)
    const lastTemperatureReading = fetchedTemperatureCSVData[fetchedTemperatureCSVData.length - 1]
    return (
        <div>
            <div className="App">
                <p>Temperature: {lastTemperatureReading.temperature} C, Humidity: {lastTemperatureReading.humidity} % Pressure: {lastTemperatureReading.temperature} hPa </p>
                <ToggleButtonGroup
                    value={dateButton}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                >
                    <ToggleButton value="1H">
                        1H
                    </ToggleButton>
                    <ToggleButton value="3H">
                        3H
                    </ToggleButton>
                    <ToggleButton value="6H">
                        6H
                    </ToggleButton>
                    <ToggleButton value="12H">
                        12H
                    </ToggleButton>
                    <ToggleButton value="24H">
                        24H
                    </ToggleButton>
                    <ToggleButton value="3D">
                        3D
                    </ToggleButton>
                    <ToggleButton value="7D">
                        7D
                    </ToggleButton>
                    <ToggleButton value="2W">
                        2W
                    </ToggleButton>
                    <ToggleButton value="MAX">
                        MAX
                    </ToggleButton>
                </ToggleButtonGroup>
                <HumidityGraphComponent fetchedCSVData={cutData} />
                <h2>3: Crolina reaper red</h2>
                <BarComponent progress={progress0} />
                <h2>1: Trinidad green</h2>
                <BarComponent progress={progress1} />
                <h2>4: Chocolate scotch bonnet blue</h2>
                <BarComponent progress={progress2} />
                <h2>2: Trinidad 2 black</h2>
                <BarComponent progress={progress3} />
                <br />
                <GraphComponent fetchedCSVData={cutData2.map(x => { return { value: x.temperature, time: x.time } })} />
                <br />
                <GraphComponent fetchedCSVData={cutData2.map(x => { return { value: x.humidity, time: x.time } })} />
                <br />
                <GraphComponent fetchedCSVData={cutData2.map(x => { return { value: x.pressure, time: x.time } })} />
            </div>
        </div>
    )
}
