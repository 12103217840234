import React, { useState, useEffect, ReactElement } from 'react'
import { parse } from 'papaparse'
import logo from './logo.svg'
import './App.css'
import ContentEx from './components/ContentEx'
import FooterEx from './components/FooterEx'
import ResponsiveMenu from './components/ResponsiveMenu/ResonsiveMenu'
import type { CSVHumidityDataPoint } from './Types/CSVHumidityDataPoint'
import { CSVTemperatureDataPoint } from './Types/CSVTemperatureDataPoint'

type CSVHumidityDataArray = CSVHumidityDataPoint[] | null;
type CSVTemperatureDataArray = CSVTemperatureDataPoint[] | null;

function App() : ReactElement {
    const initialState: CSVHumidityDataArray = null
    const initialState2: CSVHumidityDataArray = null
    const [fetchedHumidityCSVData, setFetchedHumidityCSVData] = useState<CSVHumidityDataArray>(initialState)
    const [fetchedTemperatureCSVData, setFetchedTemperatureCSVData] = useState<CSVTemperatureDataArray>(initialState2)

    useEffect(() => {
        async function getCSVHumidityData() {
            const response = await fetch(`${process.env.PUBLIC_URL}/data/humidity7DaysRolling.csv`)
            const text = await response.text()
            const parseResponse = parse<string[]>(text)
            parseResponse.data.splice(-1,1)
            const formattedData = parseResponse.data.map((x) : CSVHumidityDataPoint  => {
                return {
                    time: x[0],
                    value0: Number(x[1]),
                    voltage0: Number(x[2]),
                    value1: Number(x[3]),
                    voltage1: Number(x[4]),
                    value2: Number(x[5]),
                    voltage2: Number(x[6]),
                    value3: Number(x[7]),
                    voltage3: Number(x[8])
                }
            }).filter(x => new Date(x.time) > new Date(2020, 4, 30, 21, 30, 0, 0))
            setFetchedHumidityCSVData(formattedData)
        }
        async function getCSVTemperatureData() {
            const response = await fetch(`${process.env.PUBLIC_URL}/data/temperature7DaysRolling.csv`)
            const text = await response.text()
            const parseResponse = parse<string[]>(text)
            parseResponse.data.splice(-1,1)
            const formattedData = parseResponse.data.map((x) : CSVTemperatureDataPoint  => {
                return {
                    time: x[0],
                    temperature: Math.round((Number(x[1]) + Number.EPSILON) * 100) / 100,
                    humidity: Math.round((Number(x[2]) + Number.EPSILON) * 100) / 100,
                    pressure: Math.round((Number(x[3]) + Number.EPSILON) * 100) / 100
                }
            }).filter(x => new Date(x.time) > new Date(2020, 4, 30, 21, 30, 0, 0))
            setFetchedTemperatureCSVData(formattedData)
        }
        getCSVHumidityData()
        getCSVTemperatureData()
    }, [])

    if(!fetchedHumidityCSVData || !fetchedTemperatureCSVData) {
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                </header>
            </div>
        )
    }

    return (
        <ResponsiveMenu lastUpdate={fetchedHumidityCSVData[fetchedHumidityCSVData.length - 1].time}>
            <>
                <ContentEx fetchedHumidityCSVData={fetchedHumidityCSVData} fetchedTemperatureCSVData={fetchedTemperatureCSVData} />
                <FooterEx />
            </>
        </ResponsiveMenu>
    )
}

export default App
