import React, { ReactElement } from 'react'
import { ResponsiveContainer, LineChart, Line, Legend, Tooltip, CartesianGrid, XAxis, YAxis } from 'recharts'
import type { CSVHumidityDataPoint } from '../Types/CSVHumidityDataPoint'

export default function HumidityGraphComponent(prop: {fetchedCSVData: CSVHumidityDataPoint[]}) : ReactElement {
    const { fetchedCSVData } = prop
    const maxPrRow = fetchedCSVData.map(row => {
        const value = Math.max(row.value0, row.value1, row.value3)
        return value
    })
    const max = Math.max(...maxPrRow) + 1000

    const minPrRow = fetchedCSVData.map(row => {
        const value = Math.min(row.value0, row.value1, row.value3)
        return value
    })
    const min = Math.min(...minPrRow) - 1000
    const realMin = min < 0 ? 0 : min

    return (
        <div>
            <ResponsiveContainer width="95%" height={600}>
                <LineChart width={1000} height={600} data={fetchedCSVData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                    <Line type="monotone" dataKey="value1" name="1: Trinidad 2024" stroke="green" dot={false} />
                    <Line type="monotone" dataKey="value0" name="3: hocolate scotch bonnet" stroke="red" dot={false} />
                    {/* <Line type="monotone" dataKey="value2" name="4: Chocolate scotch bonnet" stroke="blue" dot={false} /> */}
                    <Line type="monotone" dataKey="value3" name="2: Trinidad 2023" stroke="black" dot={false} />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey="time" />[]
                    <Tooltip />
                    <Legend />
                    <YAxis type="number" domain={[realMin, max]} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}
